const isPassthroughKey = (key) => (
  /^debug$/i.test(key) ||
  /^skipCache$/i.test(key) ||
  /^isLoadTest$/i.test(key) ||
  /^highlightedId$/i.test(key)
)

export const GET_PASSTHROUGH_PARAMS = (params) => {
  return params
    ? Object.keys(params)
      .filter(key => isPassthroughKey(key))
      .reduce((obj, key) => ({
        ...obj,
        [key]: params[key]
      }), {})
    : {}
}

import { getToken } from '../../../js/helpers/tokens'

// Import tokens
const tokensShared = require('./data/c-map-interactive__tokens.json')['c-map-interactive']
const tokensBrand = (() => {
  try {
    return require(`../../../../brands/${WEBPACK_BRAND}/modules/components/map-interactive/data/c-map-interactive__tokens.json`)['c-map-interactive'] // eslint-disable-line
  } catch (error) {
    return {}
  }
})()
const TOKENS = { ...tokensShared, ...tokensBrand }

export const DEFINITION = {
  name: 'c-map-interactive',
  props: []
}

export const MARKERS = ['center', 'nearby', 'unavailable'].map(marker => getToken('markers', marker, TOKENS))

export const CLUSTER_STYLES = getToken('clusterStyles', 'default', TOKENS).styles || {}

export const CUSTOM_MARKER_NAME = `${DEFINITION.name}__custom-marker`

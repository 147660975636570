import Component from '../../../js/core/component/component'
import MarkerClusterer from '@google/markerclustererplus/dist/markerclustererplus.umd'
import { registerComponent } from '../../../js/core/component/component-directory'
import { documentObserver, observerAPI } from '../../../js/document/intersector'
import { MapCardTemplate } from './map-card.template'
import { getData } from '../../../js/document/html-helper'
import { forceRepaint } from '../../../js/document/css'

// Ensure other child component APIs are loaded on time
import Img from '../img/main'

import {
  DEFINITION,
  MARKERS,
  CLUSTER_STYLES,
  CUSTOM_MARKER_NAME
} from './config.js'

const imgComponentAPI = 'c-img'

const unavailableMarkerClasses = 'c-btn__icon m-icon--home c-map-interactive__unavailable-custom-marker'
const destinationMarkerClass = 'c-map-interactive__destination-custom-marker'

const delayMilliseconds = 100

export default class InteractiveMap extends Component {
  /**
   * Creates a new Map
   *
   * @constructor
   *
   * @param {HTMLElement} element - The HTML component element.
   * @param {AutocompleteOptions} [options={}] - Options object
   *
   */
  constructor (element, options = {}) {
    super(element, DEFINITION.name)
    this.element = element
    this.settings = { ...getData(this.element), ...options }
    this.modal = element.querySelector('.c-modal')
    this.mapContainer = element.querySelector('.c-map-interactive__container')
    this.mapListener = null
    this.markers = []
    this.clusteredMarkers = []
    this.markerClusterer = null
    this.activeMarker = null

    const observer = documentObserver()
    observer.observe(this.element)
    this.element[observerAPI].events.on('enter', () => {
      this._loadGoogleMapsApi()
      observer.unobserve(this.element)
    })

    this.element[DEFINITION.name].showMap = this._showMap.bind(this)
    this.element[DEFINITION.name].init = this._init.bind(this)
    this.element[DEFINITION.name].setCenter = this._setCenter.bind(this)
    this.element[DEFINITION.name].setZoom = this._setZoom.bind(this)
    this.element[DEFINITION.name].panTo = this._panTo.bind(this)
    this.element[DEFINITION.name].getBounds = this._getBounds.bind(this)
    this.element[DEFINITION.name].addMarker = this._addMarker.bind(this)
    this.element[DEFINITION.name].createClusterMarkers = this._createClusterMarkers.bind(this)
    this.element[DEFINITION.name].removeClusterMarkers = this._removeClusterMarkers.bind(this)
    this.element[DEFINITION.name].clearMarkers = this._clearMarkers.bind(this)
    this.element[DEFINITION.name].clearInfoWindow = this._clearInfoWindow.bind(this)
    this.element[DEFINITION.name].showInfoWindow = this._showInfoWindow.bind(this)
    this.element[DEFINITION.name].zoomToMarkers = this._zoomToMarkers.bind(this)
    this.element[DEFINITION.name].getMap = this._getMap.bind(this)
    this.element[DEFINITION.name].getActiveMarker = this._getActiveMarker.bind(this)
    this.element[DEFINITION.name].setLabelMarker = this._setLabelMarker.bind(this)
    this.element[DEFINITION.name].removeActiveMarker = this._removeActiveMarker.bind(this)
    this.element[DEFINITION.name].getMarker = this._getMarker.bind(this)
    this.element[DEFINITION.name].getMarkerClusterer = this._getMarkerClusterer.bind(this)
  }

  _loadGoogleMapsApi () {
    if (typeof window.google === 'undefined') {
      const script = document.createElement('script')
      script.onload = () => this._showMap()

      /**
       * On the usage of keys:
       * Dev key: AIzaSyBXav_hGR4RQsPpeIViofRqGoSCs7xB3UI
       * DS key: AIzaSyDvA62n4OKm99LsYKESyWYoBX_w0VVcQzs
       *
       * Dev key has no limitations, so use this for development only
       * When pushing code, make sure the DS key is used
       */
      script.src = this._signUrl(`https://maps.googleapis.com/maps/api/js?key=${this.settings.apiKey}`, this.settings.signature)
      document.getElementsByTagName('head')[0].appendChild(script)
    } else {
      this._showMap()
    }
  }

  _showMap () {
    const latitude = parseFloat(this.settings.latitude)
    const longitude = parseFloat(this.settings.longitude)
    const zoom = parseInt(this.settings.zoomLevel)
    const markerId = 0
    const icons = {
      default: (this.settings.centerMarker) ? this.settings.centerMarker : this.settings.centerMarkerStatic,
      active: (this.settings.centerMarkerActive) ? this.settings.centerMarkerActive : false
    }
    this._waitForLoadedMapBeforeOpening(latitude, longitude, zoom)

    if (this.settings.showCenterMarker === true) {
      this._addMarker(markerId, latitude, longitude, icons, 'center', false)
    }
  }

  _setCenter (latitude, longitude) {
    if (!this.map) {
      throw new Error('map is not initialized')
    }

    const location = new window.google.maps.LatLng(latitude, longitude)
    this.map.setCenter(location)
  }

  _setZoom (zoom) {
    if (!this.map) {
      throw new Error('map is not initialized')
    }

    this.map.setZoom(zoom)
  }

  _panTo (latLang) {
    if (!this.map) {
      throw new Error('map is not initialized')
    }

    this.map.panTo(latLang)
  }

  _getBounds (latLang) {
    if (!this.map) {
      throw new Error('map is not initialized')
    }

    return this.map.getBounds(latLang)
  }

  _init (latitude, longitude, zoom) {
    const location = new window.google.maps.LatLng(latitude, longitude)
    if (this.map) {
      const mapCenter = this.map.getCenter()
      if (mapCenter.lat() !== latitude || mapCenter.lng() !== longitude) {
        this.map.setCenter(location)
      }

      const mapZoom = this.map.getZoom()
      if (mapZoom !== zoom) {
        this.map.setZoom(zoom)
      }

      if (this.markerClusterer) {
        window.google.maps.event.addListenerOnce(this.map, 'idle', () => {
          this.markerClusterer.repaint()
        })
      }
    } else {
      this.map = new window.google.maps.Map(
        this.mapContainer,
        {
          zoom,
          maxZoom: 18,
          fullscreenControl: false,
          mapTypeControl: false,
          center: location,
          controlSize: 26,
          clickableIcons: false,
          gestureHandling: 'greedy',
          streetViewControl: false,
          /**
           * Below style options are to completely hide/remove the Places and Markers
           * that Google adds by default. Design guidelines specified to only show
           * the markers that we add ourselves.
           */
          styles: [
            {
              featureType: 'administrative',
              elementType: 'geometry',
              stylers: [{ visibility: 'off' }]
            },
            {
              featureType: 'poi',
              stylers: [{ visibility: 'off' }
              ]
            },
            {
              featureType: 'road',
              elementType: 'labels.icon',
              stylers: [{ visibility: 'off' }]
            },
            {
              featureType: 'transit',
              stylers: [{ visibility: 'off' }]
            }
          ]
        }
      )
    }
  }

  _addMarker (markerId, latitude, longitude, icons, markerType, clusterMarker, markerEvents, customTextMarker, resultType) {
    const currentMarker = MARKERS.find(marker => marker.name === markerType)
    const position = new window.google.maps.LatLng(latitude, longitude)
    const scaledSize = new window.google.maps.Size(currentMarker.width, currentMarker.height)
    const isDestinationMarker = (resultType && resultType !== 'Accommodation')
    const customBackgroundMarkerSvg = isDestinationMarker
      ? { url: this._getGoogleClusterInlineSvg(CLUSTER_STYLES.backgroundColor), scaledSize }
      : {
          path: 'M -3 -1 L -3 1 L 3 1 L 3 -1 Z',
          strokeOpacity: 0,
          scale: 8
        }
    const iconMarker = customTextMarker
      ? customBackgroundMarkerSvg
      : { url: (markerType === 'center' && icons.active) ? icons.active : icons.default, scaledSize }

    const isUnavailable = markerType === 'unavailable'

    const labelMarker = customTextMarker
      ? {
          text: customTextMarker,
          className: isDestinationMarker
            ? isUnavailable
              ? `${destinationMarkerClass} destination-marker__${markerId} ${unavailableMarkerClasses}`
              : `${destinationMarkerClass} destination-marker__${markerId}`
            : isUnavailable
              ? `${CUSTOM_MARKER_NAME} ${unavailableMarkerClasses}`
              : `${CUSTOM_MARKER_NAME}`
        }
      : null

    const marker = new window.google.maps.Marker(
      {
        markerId,
        markerType,
        position,
        map: this.map,
        optimized: false,
        icon: iconMarker,
        icons,
        label: labelMarker,
        resultType,
        visited: false
      }
    )

    this.markers.push(marker)

    if (customTextMarker) this._setHoverEventOnCustomMarker(marker, isDestinationMarker)
    if (clusterMarker) this.clusteredMarkers.push(marker)
    if (markerType === 'center') this.activeMarker = marker

    if (markerEvents) {
      markerEvents.forEach(markerEvent => {
        if (markerEvent.type === 'click') {
          this._addClickMarkerEvent(marker, markerEvent, customTextMarker, icons, scaledSize)
        } else {
          marker.addListener(markerEvent.type, (ev) => {
            markerEvent.callBack(ev, marker, this.map)
          })
        }
      })
    }
  }

  _setLabelMarker (marker, labelText, active, hoverIn, bounce = false) {
    const customClassMarkerTextActive = `${CUSTOM_MARKER_NAME}--active`
    const customClassMarkerTextHoverIn = `${CUSTOM_MARKER_NAME}--hover-in`
    const customClassMarkerTextBounce = `${CUSTOM_MARKER_NAME}--bounce`
    const customClassMarkerTextVisited = `${CUSTOM_MARKER_NAME}--visited`

    const isUnavailable = marker.markerType === 'unavailable'
    const visited = marker.visited
    // label with hover effect
    let customClassMarker = hoverIn ? `${CUSTOM_MARKER_NAME} ${customClassMarkerTextHoverIn}` : CUSTOM_MARKER_NAME
    // label with visited effect
    customClassMarker = visited ? `${CUSTOM_MARKER_NAME} ${customClassMarkerTextVisited}` : customClassMarker
    // label with hover effect and bounce effect
    customClassMarker = hoverIn && bounce ? `${customClassMarker} ${customClassMarkerTextBounce}` : customClassMarker
    // label with active effect
    customClassMarker = active ? `${CUSTOM_MARKER_NAME} ${customClassMarkerTextActive}` : customClassMarker
    const zIndexMarker = active || hoverIn ? google.maps.Marker.MAX_ZINDEX + 1 : undefined // eslint-disable-line no-undef

    marker.setLabel({
      text: labelText,
      className: isUnavailable ? `${customClassMarker} ${unavailableMarkerClasses}` : customClassMarker
    })
    marker.setZIndex(zIndexMarker)
  }

  _setHoverEventOnCustomMarker (marker, isDestinationMarker = false) {
    if (isDestinationMarker) {
      marker.addListener('mouseover', () => {
        const id = marker.markerId
        const label = document.querySelector(`.destination-marker__${id}`)
        label.classList.add('hover')
      })

      marker.addListener('mouseout', () => {
        const id = marker.markerId
        const label = document.querySelector(`.destination-marker__${id}`)
        label.classList.remove('hover')
      })
    } else {
      const markerActiveClass = `${CUSTOM_MARKER_NAME}--active`
      marker.addListener('mouseover', () => {
        if (!marker.label.className.includes(markerActiveClass)) {
          this._setLabelMarker(marker, marker.label.text, false, true)
        }
      })

      marker.addListener('mouseout', () => {
        if (!marker.label.className.includes(markerActiveClass)) {
          this._setLabelMarker(marker, marker.label.text, false, false)
        }
      })
    }
  }

  _addClickMarkerEvent (marker, markerEvent, customTextMarker, icons, scaledSize) {
    marker.addListener(markerEvent.type, (ev) => {
      const isMarkerActive = !!((this.activeMarker && (this.activeMarker.markerId === marker.markerId)))
      if (!isMarkerActive && !customTextMarker) {
        if (this.activeMarker) {
          this.activeMarker.setIcon({
            url: this.activeMarker.icons.default,
            scaledSize: this.activeMarker.icon.scaledSize
          })
        }
        marker.setIcon({
          url: (icons.active) ? icons.active : icons.default,
          scaledSize
        })
        this.activeMarker = marker
      } else if (customTextMarker && !isMarkerActive) {
        this._setLabelMarker(marker, customTextMarker, true, false)
        if (this.activeMarker && this.activeMarker.label) {
          this.activeMarker.visited = true
          this._setLabelMarker(this.activeMarker, this.activeMarker.label.text, false, false)
        }
        this.activeMarker = marker
      }
      markerEvent.callBack(ev, marker, this.map)
    })
  }

  _getMarker (markerId) {
    return this.markers.find((marker) => marker.markerId == markerId) // eslint-disable-line eqeqeq
  }

  _resetActiveMarker () {
    const defaultMarker = this.markers.find((marker) => marker.markerId === 0)
    defaultMarker.setIcon({
      url: defaultMarker.icons.active,
      scaledSize: defaultMarker.icon.scaledSize
    })
    if (this.activeMarker.markerId !== 0) {
      this.activeMarker.setIcon({
        url: this.activeMarker.icons.default,
        scaledSize: this.activeMarker.icon.scaledSize
      })
    }
    this.activeMarker = defaultMarker
  }

  _showInfoWindow (cardHtml) {
    const templateData = {
      html: cardHtml.trim()
    }

    this._clearInfoWindow()
    this._createInfoWindow(templateData)
  }

  _getGoogleClusterInlineSvg (color) {
    const encoded = window.btoa(`<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="-100 -100 200 200"><g fill="${color}"><circle r="100" /></g></svg>`)
    return ('data:image/svg+xml;base64,' + encoded)
  }

  _createClusterMarkers (options) {
    const clusterStyles = {
      width: CLUSTER_STYLES.size,
      height: CLUSTER_STYLES.size,
      url: this._getGoogleClusterInlineSvg(CLUSTER_STYLES.backgroundColor),
      fontFamily: CLUSTER_STYLES.fontFamily,
      textColor: CLUSTER_STYLES.textColor,
      textSize: CLUSTER_STYLES.textSize,
      anchorText: [CLUSTER_STYLES.textOffset, 0],
      className: 'c-map-interactive__cluster'
    }

    this.markerClusterer = new MarkerClusterer(this.map, this.clusteredMarkers,
      {
        maxZoom: this.settings.clusteringThreshold,
        averageCenter: true,
        styles: [clusterStyles],
        minimumClusterSize: options ? options.minimumClusterSize : 2,
        gridSize: options ? options.gridSize : 60,
        ignoreHidden: true
      }
    )
  }

  _removeClusterMarkers () {
    this.markerClusterer = null
  }

  _createInfoWindow (templateData) {
    const card = document.createElement('div')
    card.classList.add('c-map-interactive__card-container')
    card.innerHTML = MapCardTemplate(templateData)
    this.mapContainer.appendChild(card)

    card.querySelector('.c-map-interactive__card').classList.add('is-opening')
    forceRepaint(card)
    card.querySelector('.c-map-interactive__card').classList.add('in')

    if (this.mapListener) {
      window.google.maps.event.removeListener(this.mapListener)
    }

    this.mapListener = window.google.maps.event.addListenerOnce(this.map, 'click', () => {
      this._closeInfoWindow(card)
    })

    const close = card.querySelector('.c-map-interactive__card-close')
    close.addEventListener('click', () => {
      this._closeInfoWindow(card)
    })

    const images = Array.from(card.querySelectorAll(`[data-js-component*="${imgComponentAPI}"]`))
    if (images) {
      Img.createInstancesOnDocument(card)

      images.map((el) => el[imgComponentAPI])
        .forEach((imgApi) => {
          imgApi.resolve()
        })
    }
  }

  _zoomToMarkers () {
    const bounds = new window.google.maps.LatLngBounds()
    for (let i = 0; i < this.markers.length; i++) {
      bounds.extend(this.markers[i].position)
    }

    this.map.fitBounds(bounds)
  }

  _closeInfoWindow (card) {
    card.querySelector('.c-map-interactive__card').classList.add('is-closing')
    card.querySelector('.c-map-interactive__card').classList.remove('in')
    card.addEventListener('transitionend', function () {
      if (card.parentNode) {
        card.parentNode.removeChild(card)
      }
    })
    this._resetActiveMarker()
  }

  _clearInfoWindow () {
    const cardContainers = this.mapContainer.querySelectorAll('.c-map-interactive__card-container')
    if (cardContainers) {
      cardContainers.forEach(node => node.parentNode.removeChild(node))
    }
  }

  _clearMarkers () {
    if (this.markerClusterer) {
      this.markerClusterer.clearMarkers()
      this.markerClusterer.setMap(null)
    } else {
      this.markers.forEach(marker => {
        marker.setMap(null)
      })
    }

    this.markers = []
    this.clusteredMarkers = []
    this._removeActiveMarker()
  }

  _getMap () {
    return this.map
  }

  _getActiveMarker () {
    return this.activeMarker
  }

  _removeActiveMarker () {
    this.activeMarker = null
  }

  _getMarkerClusterer () {
    return this.markerClusterer
  }

  _waitForLoadedMapBeforeOpening (latitude, longitude, zoom) {
    if (window.google && window.google.maps) {
      this._init(latitude, longitude, zoom)
    } else {
      setTimeout(() => {
        this._waitForLoadedMapBeforeOpening(latitude, longitude, zoom)
      }, delayMilliseconds)
    }
  }

  /**
     * Sign a URL using a secret key.
     *
     * @param  {string} path   The url you want to sign.
     * @param  {string} secret Your unique secret key.
     * @return {string}
     */
  _signUrl (path, secret) {
    const crypto = require('crypto')
    const url = require('url')
    const uri = new URL(path)
    const safeSecret = decodeBase64Hash(removeWebSafe(secret))
    const hashedSignature = makeWebSafe(encodeBase64Hash(safeSecret, uri.pathname))

    return url.format(uri) + '&signature=' + hashedSignature

    /**
     * Convert from 'web safe' base64 to true base64.
     *
     * @param  {string} safeEncodedString The code you want to translate
     *                                    from a web safe form.
     * @return {string}
     */
    function removeWebSafe (safeEncodedString) {
      return safeEncodedString.replace(/-/g, '+').replace(/_/g, '/')
    }

    /**
     * Convert from true base64 to 'web safe' base64
     *
     * @param  {string} encodedString The code you want to translate to a
     *                                web safe form.
     * @return {string}
     */
    function makeWebSafe (encodedString) {
      return encodedString.replace(/\+/g, '-').replace(/\//g, '_')
    }

    /**
     * Takes a base64 code and decodes it.
     *
     * @param  {string} code The encoded data.
     * @return {string}
     */
    function decodeBase64Hash (code) {
      return Buffer.from ? Buffer.from(code, 'base64') : Buffer.from(code, 'base64')
    }

    /**
     * Takes a key and signs the data with it.
     *
     * @param  {string} key  Your unique secret key.
     * @param  {string} data The url to sign.
     * @return {string}
     */
    function encodeBase64Hash (key, data) {
      return crypto.createHmac('sha1', key).update(data).digest('base64')
    }
  }
}

registerComponent(InteractiveMap, DEFINITION.name, DEFINITION)

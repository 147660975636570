/**
 * The CardData contains all data needed to hydrate the Map Card
 *
 * @typedef {Object}          CardData
 *
 * @property {String}         html                - Content of the card
 *
 */
export const defaultCardData = {
  html: ''
}

export const MapCardTemplate = (d) => {
  d = { ...defaultCardData, ...d }

  return `
    <div class="c-map-interactive__card">
      <span class="m-icon--cross c-map-interactive__card-close"></span>
      ${d.html}
    </div>
    `
}

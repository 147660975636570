import { RatingTemplate } from './../rating/c-rating.template'

const IconApiDictionary = {
  bed: 'bed',
  bus: 'bus-right'
}

/**
 * The BulletListItemData contains all data needed to hydrate a Button Group selector view
 *
 * @typedef {Object}          BulletListItemData
 *
 * @property {String}         text                - Bullet point text
 * @property {String}         icon                - Bullet point icon
 * @property {Boolean}        isIconHidden
 * @property {Boolean}        isBold              - If true, item has bold text
 */
export const defaultBulletListItemData = {
  text: '',
  icon: '',
  isIconHidden: false,
  isBold: false,
  link: {
    href: '',
    target: '',
    title: ''
  }
}

export const BulletListItemTemplate = (d) => {
  // icons
  if (d.icon.indexOf('icon-') === 0) {
    d.icon = d.icon.replace(/^icon-/, '')
  }
  d.icon = IconApiDictionary[d.icon] || d.icon

  // metadata
  if (d.metadata && d.metadata.includes('numberOfStars:')) {
    d.stars = d.metadata.split(':')[1].replace(',', '.')
  }

  d = { ...defaultBulletListItemData, ...d }

  return `
<li class="c-bullet-list__item ${d.link.href ? 'c-bullet-list__item--link' : ''} ${d.isBold ? 'c-bullet-list__item--bold' : ''}">
    <span class="c-bullet-list__icon m-icon--${d.icon || 'checkmark'} o-bullet__icon ${d.isIconHidden ? 'c-bullet-list__icon--hidden' : ''}"></span> 
    
    ${d.link.href
    ? `<a class="c-bullet-list__link o-bullet__text o-bullet__text--middle" 
       href='${d.link.href}'
       target='${d.link.target}'
       title='${d.link.title}'>
      ${d.text}
    </a>`
    : `<span class="c-bullet-list__text o-bullet__text o-bullet__text--middle">${d.text}</span>${d.stars ? RatingTemplate({ value: d.stars }) : ''}`}
  </li>
`
}

const isWhitelistedKey = (key) => (
  /^gclid$/i.test(key) ||
  /^gclsrc$/i.test(key) ||
  /^utm_/i.test(key) ||
  /^dclid/i.test(key) ||
  /^agentCode/i.test(key) ||
  /^nui/i.test(key) ||
  /^m_i/i.test(key) ||
  /^fbclid/i.test(key)
)

export const GET_WHITELISTED_PARAMS = (params) => {
  return params
    ? Object.keys(params)
      .filter(key => isWhitelistedKey(key))
      .reduce((obj, key) => ({
        ...obj,
        [key]: params[key]
      }), {})
    : {}
}

export const EXCLUDE_WHITELISTED_PARAMS = (params) => {
  return params
    ? Object.keys(params)
      .filter(key => !isWhitelistedKey(key))
      .reduce((obj, key) => ({
        ...obj,
        [key]: params[key]
      }), {})
    : {}
}

import { BulletListItemTemplate } from './c-bullet-list__item.template'

/**
 * The BulletListData contains all data needed to hydrate a Bullet List selector view
 *
 * @typedef {Object}                  BulletListData
 *
 * @property {String}                 variant             - Bullet List modifier
 * @property {String}                 icon                - General icon for bullet list
 * @property {String}                 extraClasses
 * @property {BulletListItemData[]}   items               - Bullet list items ({text, icon})
 */
export const defaultBulletListData = {
  variant: '',
  icon: 'checkmark',
  extraClasses: '',
  items: []
}

export const BulletListTemplate = (d) => {
  d = { ...defaultBulletListData, ...d }
  return `${d.items
    ? `<ul class="c-bullet-list ${d.variant ? `c-bullet-list--${d.variant}` : ''} o-list-bare ${d.extraClasses} sp-stack-children--small">
      ${d.items.map((item) => BulletListItemTemplate({ icon: d.icon, ...item })).join('')}
    </ul>`
  : ''}`
}
